import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const languages = (t: (key: string) => string) => [
    { value: 'en', label: t('header.en') },
    { value: 'de', label: t('header.de') },
];

export const LanguageSwitcher = () => {
    const { t, i18n: { changeLanguage, language } } = useTranslation();

    const onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
        changeLanguage(e.target?.value);
    };

    return (
        <div className="language-button">
            <select onChange={onLanguageChange}>
                {languages(t).map((item) => (
                    <option
                        key={item.value}
                        value={item.value}
                        selected={language === item.value}
                    >
                        {item.label}
                    </option>
                ))}
            </select>
        </div>
    );
};
