import { useMutation } from '@tanstack/react-query';
import { sendCallbackInfoApiService } from './sendCallback.service';
import { ISendCallBackResponse, TSendCallBackRequest } from './sendCallback.model';

export const useSendCallbackInfo = () => {
    const mutation = useMutation<ISendCallBackResponse, unknown, TSendCallBackRequest>(
        (data:TSendCallBackRequest) => sendCallbackInfoApiService.sendCallbackInfo(data),
    );

    return {
        sendCallback: mutation.mutateAsync,
        isCallBackSendLoading: mutation.isLoading,
        sendCallBackData: mutation.data,
    };
};
