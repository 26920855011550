import { useQuery } from '@tanstack/react-query';
import { getFlatByIdApiService } from './getFlatById.service';
import { IGetFlatByIdModelResponse } from './getFlatById.model';

export const useGetFlatById = (id:string) => {
    const { isFetching: isLoading, data } = useQuery<IGetFlatByIdModelResponse>(
        ['get-selected-flat'],
        () => getFlatByIdApiService.getFlatById(id),
    );

    return {
        selectedFlatData: data,
        isLoadingSelectedFlat: isLoading,
    };
};
