import { Swiper, SwiperSlide } from 'swiper/react';

import {
    Pagination,
} from 'swiper';
import { Key } from 'react';
import feedbackStar from '../../assets/svg/feedback-star.svg';

interface IFeedbackProps {
    width: number
}

interface IFeedback {
    imageUrl?: string;
    name: string;
    text: string;
    starCount: number
}

const feedbacks: IFeedback[] = [
    {
        name: 'Burcu',
        text: ` It was great, thank you.
                        The toilet is heated!! and the
                        little terrace is so nice to spend time in.`,
        starCount: 5,
    },
    {
        name: 'Alisa',
        text: ` Exceptional.
                        Very well equipped flat, nice and comfortable.
                        Suitable even for longer stay for family or friends.`,
        starCount: 5,
    },
    {
        name: 'Paola',
        text: ` Excelente.
                        Es un departamento excelente, vale mucho la pena si vas en grupo.
                         las instalaciones son muy limpias y la cocina está increíble.
                          Todo esta muy bien! Definitivamente me volvería a quedar ahí`,
        starCount: 4,
    },
    {
        name: 'Dina',
        text: 'Superb',
        starCount: 4,
    },
    {
        imageUrl: 'https://graph.facebook.com/v2.9/10155712776698864/picture?type=square&height=64&width=64',
        name: 'Werner',
        text: ' Very good',
        starCount: 4,
    },
];
export const FeedbackBlock = (props: IFeedbackProps) => {
    const { width } = props;
    const generateSwiperSlide = (data: IFeedback) => (
        <>
            <div className="feedback-author">
                {data.imageUrl
                    ? <img src={data.imageUrl} alt="author of the feedback" />
                    : <div className="withoutImageAuthor">{data.name.charAt(0).toUpperCase()}</div>}
                <span>{data.name}</span>
            </div>
            <div className="feedback-text">
                <p>
                    {data.text}
                </p>
            </div>
            <div className="feedback-rate">
                {
                    Array.from(
                        { length: data.starCount },
                        (_: any, index: Key | null | undefined) => (
                            <img key={index} src={feedbackStar} alt="" />
                        ),
                    )
                }
            </div>
        </>
    );

    const getSlidesPerView = (widthNumber:number) => {
        if (widthNumber > 1300) {
            return 4;
        } if (widthNumber > 768) {
            return 2;
        }
        return 1;
    };

    return (
        <Swiper
            slidesPerView={getSlidesPerView(width)}
            spaceBetween={70}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            className="swiper"
        >
            {feedbacks.map((feedback) => (
                <SwiperSlide>
                    {generateSwiperSlide(feedback)}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
