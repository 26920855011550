import { useCallback, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    GoogleMap, Marker, InfoWindow, useJsApiLoader,
} from '@react-google-maps/api';
import { selectAllFlats } from '../../store/slices/flats';
import { selectLandingInfo } from '../../store/slices/landingInfo';
import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';

const markerArray = [
    {
        name: 'Senator Flat Paulusgasse 6',
        id: '64b6adb801bd4f4c7bcce208',
        positions: {
            lat: 48.193214020335674,
            lng: 16.400308494911986,
        },
    },

    {
        name: 'Senator Flat Paulus',
        id: '64b85c4e04007b2434632608',
        positions: {
            lat: 48.19362373936235,
            lng: 16.400575738746756,
        },
    },
    {
        name: 'Senator Flat Petrus',
        id: '64b9a1d94f10d4d221599413',
        positions: {
            lat: 48.19398417873759,
            lng: 16.400104862898385,
        },
    },
    {
        name: 'Senator Flat MaHü',
        id: '64b9aa134f10d4d221599422',
        positions: {
            lat: 48.19208730245944,
            lng: 16.33860668107567,
        },
    },
    {
        name: 'Senator Flat Kaiser',
        id: '64c043971195e6dbf843b7a1',
        positions: {
            lat: 48.2064723236695,
            lng: 16.339361296418524,
        },
    },
    {
        name: 'Senator Flat Handelskai Top 44',
        id: '64c58eb3e888adb2852cbb75',
        positions: {
            lat: 48.22372334062895,
            lng: 16.407334867584087,
        },
    },
    {
        name: 'Senator Flat Handelskai Top 55',
        id: '64c5a193e888adb2852cbbf1',
        positions: {
            lat: 48.22372334062895,
            lng: 16.407334867584087,
        },
    },
    {
        name: 'Senator Flat Handelskai Top 56',
        id: '64da9e401d807e9732ddbe4d',
        positions: {
            lat: 48.22372334062895,
            lng: 16.407334867584087,
        },
    },
    {
        name: 'Senator Flat Kardinal Nagl',
        id: '64dc93925687ce8a4f2ce633',
        positions: {
            lat: 48.19398417873759,
            lng: 16.400104862898385,
        },
    },
    {
        name: 'Senator Flat Engerth',
        id: '64dd517045c328aeda99a5ba',
        positions: {
            lat: 48.23095659884869,
            lng: 16.395990767584564,
        },
    },
    {
        name: 'Senator Flat Apostel 1',
        id: '64dd540b45c328aeda99a5c0',
        positions: {
            lat: 48.19869494976485,
            lng: 16.396824008951924,
        },
    },
    {
        name: 'Senator Flat Apostel 2',
        id: '64ddf4f4e023854b9adf4b2a',
        positions: {
            lat: 48.19869494976485,
            lng: 16.396824008951924,
        },
    },
    {
        name: 'Senator Flat Erdberg',
        id: '64e3261152f2c8d55456ba02',
        positions: {
            lat: 48.19744568901506,
            lng: 16.402898209911545,
        },
    },
    {
        name: 'Senator Flats Rennweg',
        id: '64e32ac552f2c8d55456ba0a',
        positions: {
            lat: 48.189033650444095,
            lng: 16.39938610991107,
        },
    },
    {
        name: 'Senator Flat Herz',
        id: '64e37f6652f2c8d55456ba1b',
        positions: {
            lat: 48.171844911658745,
            lng: 16.366105567580902,
        },
    },
    {
        name: 'Senator Flat Laxenburg',
        id: '64e393ae52f2c8d55456ba25',
        positions: {
            lat: 48.183023366668166,
            lng: 16.373918338746073,
        },
    },
    {
        name: 'Senator Flat Columbus',
        id: '64ebc2f5f1b822d3128d3ff9',
        positions: {
            lat: 48.17986001058698,
            lng: 16.374608825252356,
        },
    },
    {
        name: 'Senator Flat Buchengasse',
        id: '64ec5e24f1b822d3128d403e',
        positions: {
            lat: 48.17405208294202,
            lng: 16.38292286758107,
        },
    },
    {
        name: 'Senator Flat Reumannplatz',
        id: '64ecbaa8f1b822d3128d4148',
        positions: {
            lat: 48.17480010836029,
            lng: 16.37824862525205,
        },
    },
    {
        name: 'Senator Flat Franzl',
        id: '64ed14739982e1810f71e930',
        positions: {
            lat: 48.183959351500796,
            lng: 16.33363730991066,
        },
    },
    {
        name: 'Senator Flat Sisi',
        id: '64ee78c5b33074e7111181c9',
        positions: {
            lat: 48.183959351500796,
            lng: 16.33363730991066,
        },
    },
    {
        name: 'Senator Flat Längenfeld',
        id: '64f7903e4f2186c293678540',
        positions: {
            lat: 48.183959351500796,
            lng: 16.33363730991066,
        },
    },
    {
        name: 'Senator Flat Joschi',
        id: '64f79fac4f2186c29367854a',
        positions: {
            lat: 48.17895509559907,
            lng: 16.332779967581395,
        },
    },
];

export const MapPage = () => {
    const navigate = useNavigate();
    const [showMarkerInfoById, setShowMarkerInfoById] = useState('');
    const mapRef = useRef(undefined);
    const allFlats = useSelector(selectAllFlats);
    const { googleMapId } = useSelector(selectLandingInfo);

    // eslint-disable-next-line no-underscore-dangle
    const selectedDataById = allFlats.filter((el) => el._id === showMarkerInfoById)[0];

    const onLoad = useCallback((map:any) => {
        mapRef.current = map;
    }, []);

    const onUnmount = useCallback(() => {
        mapRef.current = undefined;
    }, []);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapId,
    });

    const handleMarkerClick = (id:string) => {
        setShowMarkerInfoById(id);
    };

    if (!isLoaded || googleMapId === '') return <div>Loading</div>;

    return (
        isLoaded
        && (
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '400px',
                }}
                center={{
                    lat: 48.193214020335674,
                    lng: 16.400308494911986,
                }}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                {markerArray.map(
                    (marker) => (
                        <Marker
                            position={marker.positions}
                            key={marker.id}
                            onClick={() => handleMarkerClick(marker.id)}
                        >
                            {showMarkerInfoById === marker.id && (
                                <InfoWindow
                                    position={marker.positions}
                                    onCloseClick={() => setShowMarkerInfoById('')}
                                >
                                    <div className="info-window">
                                        <div className="info-window-mainImage">
                                            <img src={selectedDataById.mainImage} alt="" />
                                        </div>
                                        <div className="info-window-descriptions">
                                            <h2>{selectedDataById.name}</h2>
                                            <span>
                                                Price -
                                                {' '}
                                                {selectedDataById.price}
                                                {' '}
                                                €
                                            </span>
                                            <span>
                                                For
                                                {' '}
                                                {selectedDataById.peopleCount}
                                                {' '}
                                                people
                                            </span>

                                            {/* eslint-disable-next-line no-underscore-dangle */}
                                            <button onClick={() => navigate(`/detailed-apartment/${selectedDataById._id}`)}>Open Details</button>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    ),
                )}
            </GoogleMap>
        )
    );
};
