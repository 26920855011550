import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { useTranslation } from 'react-i18next';

import {
    Button, Input, message, Spin,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import wifiIcon from '../../assets/svg/wifi-icon.svg';
import parkingIcon from '../../assets/svg/parking-icon.svg';
import userIcon from '../../assets/svg/user-icon.svg';

import EmailIcon from '../../assets/svg/email-icon.svg';
import { useGetFlatById } from '../../service/getFlatById/useGetFlatById';
import { IFlatDescription } from '../../service/getFlatById/getFlatById.model';
import { TSendCallBackRequest } from '../../service/sendCallback/sendCallback.model';
import { useSendCallbackInfo } from '../../service/sendCallback/useSendCallBack';
import Preloader from '../../components/Preloader';

import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const mockGeneralInformation:IFlatDescription = {
    name: '',
    subtitle: '',
    mainImage: '',
    images: [''],
    price: 0,
    wifi: '',
    district: '',
    transfer: '',
    peopleCount: 0,
    address: {
        city: '',
        country: '',
        addressLine: '',
    },
    _id: '',
};

export const DetailedApartmentPage = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const currentFlatId = location.pathname.split('/detailed-apartment/')[1];

    const [messageApi, contextHolder] = message.useMessage();

    const { selectedFlatData, isLoadingSelectedFlat } = useGetFlatById(currentFlatId);
    const { sendCallback, sendCallBackData, isCallBackSendLoading } = useSendCallbackInfo();

    const [inputValue, setInputValue] = useState(mockGeneralInformation);
    const [callBackValue, setCallBackValue] = useState({
        email: '',
        description: '',
    });

    const onCallBackSendSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'Your message was successfully send. \n  Thank you for your message, we will reply you back soon',
            className: 'modal-success-block',
            style: {
                marginTop: '20vh',
            },
            duration: 1.5,
        });
    };

    useEffect(() => {
        if (
            selectedFlatData !== undefined
            && !isLoadingSelectedFlat
        ) {
            setInputValue(selectedFlatData.flat);
            setCallBackValue({
                ...callBackValue,
                description: `Sended from Flat ${selectedFlatData.flat.name}`,
            });
        }
    }, [selectedFlatData, isLoadingSelectedFlat, setInputValue, setCallBackValue, callBackValue]);

    useEffect(() => {
        if (sendCallBackData !== undefined) onCallBackSendSuccess();
    }, [sendCallBackData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleSendCallBack = async (data:TSendCallBackRequest) => {
        if (data.email !== '') {
            await sendCallback({ ...data });
        }
    };

    return (
        <Preloader isLoading={isLoadingSelectedFlat}>
            <div className="detailed-apartment-page">
                <h1>{inputValue.name}</h1>
                <div className="detailed-block-wrapper">
                    <div className="media-block">
                        <Swiper
                            style={{
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff',
                            }}
                            spaceBetween={10}
                            navigation
                            loop
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                        >
                            <SwiperSlide>
                                <img src={`${(inputValue.mainImage as string).replace(/\\/g, '/')}`} alt="" />
                            </SwiperSlide>
                            {
                                inputValue.images.map((image) => (
                                    <SwiperSlide key={image as string}>
                                        <img src={`${(image as string).replace(/\\/g, '/')}`} alt="" />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <Swiper
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode
                            watchSlidesProgress
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img src={`${(inputValue.mainImage as string).replace(/\\/g, '/')}`} alt="" />
                            </SwiperSlide>
                            {
                                inputValue.images.map((image) => (
                                    <SwiperSlide key={image as string}>
                                        <img src={`${(image as string).replace(/\\/g, '/')}`} alt="" />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                    <div className="description-block">
                        <div className="description">
                            <div className="price-wrapper">
                                {/* <div className="pricing"> */}
                                {/*
                                {t('apartments.price€/night',
                                { price: inputValue.price })} */}
                                {/* </div> */}
                                <button className="check-available" onClick={() => navigate('/contact')}>
                                    {t('apartments.checkAvailability')}
                                </button>
                            </div>
                            <div className="people-count">
                                <img src={userIcon} alt="" />
                                <span>
                                    {t('apartments.forNumberPeople', { number: inputValue.peopleCount })}
                                </span>
                            </div>
                            <div className="label-block">
                                {inputValue.wifi === 'true' && (
                                    <div className="label">
                                        <img src={wifiIcon} alt="" />
                                    </div>
                                )}
                                {inputValue.transfer === 'true' && (
                                    <div className="label">
                                        <img src={parkingIcon} alt="" />
                                    </div>
                                )}
                            </div>
                            <div className="title">
                                {t('apartments.information')}
                            </div>
                            <p className="description-text">
                                {inputValue.subtitle}
                            </p>
                        </div>
                    </div>
                </div>
                {contextHolder}
                <Spin spinning={isCallBackSendLoading} delay={500}>
                    <div className="detailed-contact-block">
                        <Input
                            className="input-email"
                            placeholder={t('contact.enterEmailAddress')}
                            prefix={<img src={EmailIcon} alt="" />}
                            onChange={(e) => setCallBackValue(
                                { ...callBackValue, email: e.target.value },
                            )}
                        />
                        <Button onClick={() => handleSendCallBack(callBackValue)}>
                            {t('contact.contactUs')}
                        </Button>
                    </div>
                </Spin>
            </div>
        </Preloader>
    );
};
