import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mainLogo from '../../assets/img/logo.svg';
import hamburgerMenu from '../../assets/svg/hamburger-menu.svg';
import { selectLandingInfo } from '../../store/slices/landingInfo';
import useResize from '../../hooks/useResize';
import { LanguageSwitcher } from './LanguageSwitcher';
import './styles.scss';

export const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { width } = useResize();
    const landingInfo = useSelector(selectLandingInfo);

    const menuRef = useRef<HTMLDivElement | null>(null);

    const [menuOpen, setMenuOpen] = useState(false);

    // Close the menu when the route changes
    useEffect(() => {
        setMenuOpen(false);
    }, [location.pathname]);

    const handleMenu = () => {
        setMenuOpen((val) => !val);
    };

    useEffect(() => {
        if (width >= 1120) setMenuOpen(false);
    }, [width]);

    useEffect(() => {
        function handleClickOutside(event: TouchEvent) {
            const targetElement = event.target as EventTarget & HTMLElement;

            if (menuOpen && menuRef.current && !menuRef.current.contains(targetElement)) {
                setMenuOpen(false);
            }
        }

        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [menuOpen]);

    if (width < 1120) {
        return (
            <>
                <header id="header">
                    <div className="left-panel">
                        <a href="/" className="logo-wrapper">
                            <img src={landingInfo.mainLogo || mainLogo} alt="MainLogo" className="main-logo" />
                        </a>
                    </div>
                    <div className="right-panel">
                        <button className="hamburger-menu" onClick={handleMenu}>
                            <img src={hamburgerMenu} alt="mobile menu" />
                        </button>
                    </div>
                </header>
                {menuOpen && (
                    <div className="navigation-mobile" ref={menuRef}>
                        <div className="navigation">
                            <ul className="navigation-list">
                                <li className="active">
                                    <Link to="/">{t('navigation.home')}</Link>
                                </li>
                                <li>
                                    <Link to="/apartments">
                                        {t('navigation.apartments')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/map">
                                        {t('navigation.map')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        {t('navigation.contact')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/agb">
                                        {t('navigation.agb')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/anreise">
                                        {t('navigation.anreise')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <header id="header">
            <div className="left-panel">
                <a href="/" className="logo-wrapper">
                    <img src={landingInfo.mainLogo || mainLogo} alt="MainLogo" className="main-logo" />
                </a>
            </div>
            <div className="navigation">
                <ul className="navigation-list">
                    <li className="active">
                        <Link to="/">{t('navigation.home')}</Link>
                    </li>
                    <li>
                        <Link to="/apartments">
                            {t('navigation.apartments')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/map">
                            {t('navigation.map')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            {t('navigation.contact')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/agb">
                            {t('navigation.agb')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/anreise">
                            {t('navigation.anreise')}
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="right-panel">
                <button id="main-book-button" onClick={() => navigate('/contact')}>
                    {t('header.book')}
                </button>
                <LanguageSwitcher />
            </div>
        </header>
    );
};
