import { useEffect, useState } from 'react';
import {
    Input, Button, Spin, message,
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmailIcon from '../../assets/svg/email-icon.svg';
import { useSendCallbackInfo } from '../../service/sendCallback/useSendCallBack';
import { TSendCallBackRequest } from '../../service/sendCallback/sendCallback.model';
import { selectLandingInfo } from '../../store/slices/landingInfo';
import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';

export const ContactPage = () => {
    const { t } = useTranslation();
    const landingInfo = useSelector(selectLandingInfo);
    const { TextArea } = Input;

    const [inputValue, setInputValue] = useState<TSendCallBackRequest>({
        name: '',
        email: '',
        description: '',
    });

    const [messageApi, contextHolder] = message.useMessage();

    const {
        sendCallback,
        isCallBackSendLoading,
        sendCallBackData,
    } = useSendCallbackInfo();

    const handleSendCallBack = async (data:TSendCallBackRequest) => {
        if (data.email !== '' && data.name !== '' && data.description !== '') {
            await sendCallback({ ...data });
        }
    };

    const onCallBackSendSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'Your message was successfully send. \n  Thank you for your message, we will reply you back soon',
            className: 'modal-success-block',
            style: {
                marginTop: '20vh',
            },
            duration: 1.5,
        });
    };

    useEffect(() => {
        if (sendCallBackData !== undefined) onCallBackSendSuccess();
    }, [sendCallBackData]);

    return (
        <div className="contact-page">
            {contextHolder}
            <div className="contact-title">
                {t('contact.contactUs')}
            </div>
            <div className="contact-block">
                <div className="left-side">
                    <span>{t('contact.contactUsDescription')}</span>
                    <div className="line" />
                    <div className="contact-number">
                        <span className="bold">
                            {t('contact.ourPhoneNumber:')}
                        </span>
                        <a href={`tel:${landingInfo.phoneNumber}`}>{landingInfo.phoneNumber}</a>
                    </div>
                </div>
                <div className="right-side">
                    <Spin spinning={isCallBackSendLoading} delay={500}>
                        <div className="form-wrapper">
                            <Input
                                className="input-name"
                                placeholder={t('contact.yourName')}
                                id="name"
                                value={inputValue.name}
                                onChange={(e) => setInputValue({
                                    ...inputValue, name: e.target.value,
                                })}
                            />
                            <Input
                                className="input-email"
                                placeholder={t('contact.enterEmailAddress')}
                                prefix={<img src={EmailIcon} alt="" />}
                                id="email"
                                value={inputValue.email}
                                onChange={(e) => setInputValue({
                                    ...inputValue, email: e.target.value,
                                })}
                            />
                            <TextArea
                                className="text-area"
                                rows={4}
                                onChange={(e) => setInputValue({
                                    ...inputValue, description: e.target.value,
                                })}
                            />
                            <Button className="send-form" onClick={() => handleSendCallBack(inputValue)}>
                                {t('contact.send')}
                            </Button>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
};
