import Loader from '../Loader';

type TPreloaderProps = {
    isLoading?: boolean
    fullScreen?: boolean
    overflow?: boolean
    loaderSize?: 'small' | 'default'
    children?: JSX.Element,
};

const Preloader = (props: TPreloaderProps) => {
    const {
        isLoading,
        loaderSize,
        fullScreen,
        overflow,
        children,
    } = props;

    if (isLoading && overflow) {
        return (
            <div className="PreloaderWrapper relative before:block before:w-full before:h-full before:z-10 before:absolute before:bg-bright/60">
                {children}
                <Loader position="center" size={loaderSize} className="z-10" />
            </div>
        );
    }

    if (isLoading) {
        if (fullScreen) {
            return (
                <div className="flex-1 relative min-h-[100vh]">
                    <Loader position="center" size={loaderSize} />
                </div>
            );
        }

        return (
            <Loader size={loaderSize} className="m-auto py-4" />
        );
    }

    return children || null;
};

export default Preloader;
