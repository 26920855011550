import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Button, Row, Col, Input, Spin, message,
} from 'antd';

import Preloader from '../../components/Preloader';
import useResize from '../../hooks/useResize';

import { selectLandingInfo } from '../../store/slices/landingInfo';
import { useSendCallbackInfo } from '../../service/sendCallback/useSendCallBack';

import { selectAllFlats, selectAllFlatsLoading } from '../../store/slices/flats';
import { FeedbackBlock } from './FeedbackBlock';

import mainBanner from '../../assets/img/main-banner.png';
import contactFormBackground from '../../assets/img/contact-form-background.png';
import contactFormBanner from '../../assets/img/contact-form-banner.png';

import arrowRight from '../../assets/svg/arrow-right.svg';
import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';

export const HomePage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [visibleFlats, setVisibleFlats] = useState(4);
    const [inputValue, setInputValue] = useState('');
    const landingInfo = useSelector(selectLandingInfo);
    const allFlats = useSelector(selectAllFlats);
    const allFlatsLoading = useSelector(selectAllFlatsLoading);
    const { width } = useResize();

    const allDistricts = allFlats.map((flat) => flat.district);
    const uniqueDistricts = Array.from(
        new Set(allDistricts),
    ).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

    const [messageApi, contextHolder] = message.useMessage();

    const { sendCallback, isCallBackSendLoading, sendCallBackData } = useSendCallbackInfo();

    const onCallBackSendSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'Your message was successfully send. \n  Thank you for your message, we will reply you back soon',
            className: 'modal-success-block',
            style: {
                marginTop: '20vh',
            },
            duration: 1.5,
        });
    };

    useEffect(() => {
        if (sendCallBackData !== undefined) onCallBackSendSuccess();
    }, [sendCallBackData]);

    const handleSendCallBack = async (data:string) => {
        if (data !== '') await sendCallback({ description: data });
    };

    const handleNavigate = (id:string) => {
        navigate(`/apartments/${id}`);
    };

    const handleShowMore = () => {
        setVisibleFlats(visibleFlats + 4);
    };

    return (
        <Preloader isLoading={landingInfo.isLoading || allFlatsLoading}>
            <div className="HomePage">
                <div className="main-banner" style={{ backgroundImage: `url(${landingInfo.mainBanner || mainBanner})` }}>
                    <div className="main-wrapper">
                        <div className="banner-text">
                            <div className="banner-wrapper">
                                <h1>
                                    {landingInfo.mainTitle}
                                </h1>
                                <h2>{t('homePage.subTitle')}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flats-block">
                    <Row gutter={[40, 40]}>
                        {uniqueDistricts.map((flat, index) => {
                            if (index < visibleFlats) {
                                return (
                                /* eslint no-underscore-dangle: 0 */
                                    <Col span={width > 768 ? 12 : 24} xl={6} className="flats-block-wrapper" onClick={() => handleNavigate(flat)} key={flat}>
                                        <div className="flat-block" style={{ backgroundImage: `url(${allFlats[index].mainImage.replace(/\\/g, '/')})` }}>
                                            <div className="flat-block-description">
                                                <h3>
                                                    {flat}
                                                </h3>
                                                <span>
                                                    {t('apartments.district')}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </div>
                {visibleFlats < uniqueDistricts.length && (
                    <div className="showMore">
                        <Button onClick={handleShowMore}>
                            {t('common.showMore')}
                        </Button>
                    </div>
                )}
                <div className="contact-text">
                    <div className="line" />
                    <div className="text">
                        <span>
                            {landingInfo.callBackTitle}
                        </span>
                    </div>
                </div>
                <div className="contact-form" style={{ backgroundImage: `url(${contactFormBackground})` }}>
                    {contextHolder}
                    <Spin spinning={isCallBackSendLoading} delay={500}>
                        <div className="contact-form-wrapper">
                            <Input
                                placeholder={t('contact.yourPhoneNumber')}
                                id="number"
                                name="name"
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <Button onClick={() => handleSendCallBack(inputValue)}>
                                <span>{t('common.callback')}</span>
                                <img src={arrowRight} alt="" />
                            </Button>
                        </div>
                    </Spin>
                    {width > 1100 && (
                        <img src={contactFormBanner} alt="" />
                    )}
                </div>
                <div className="feedback-block">
                    <div className="feedback-title">
                        <div className="line" />
                        <div className="text">
                            <span>
                                {landingInfo.feedBackTitle}
                            </span>
                        </div>
                    </div>
                    <div className="feedback-wrapper">
                        <FeedbackBlock width={width} />
                    </div>
                </div>
            </div>
        </Preloader>
    );
};
