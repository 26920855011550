import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectAllFlats } from '../../store/slices/flats';
import arrowRight from '../../assets/svg/arrow-right-black.svg';
import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';

export const DistrictApartments = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const district = location.pathname.split('/apartments/')[1];
    const allFlats = useSelector(selectAllFlats);
    const tempFilteredFlats = allFlats.filter((flat) => flat._id !== '65821c917c4b0cedfdfff184');
    const handleNavigate = (id:string) => {
        navigate(`/detailed-apartment/${id}`);
    };

    const allDistricts = tempFilteredFlats.filter((flat) => flat.district === district);

    if (allDistricts.length < 0 || allDistricts.length === 0) return null;
    return (
        <div className="apartments-page">
            <div className="apartments-list">
                {
                    allDistricts.map((flat) => (
                        /*  eslint-disable no-underscore-dangle  */
                        <div className="apartment" key={flat._id}>
                            <div className="apartment-photo">
                                <button onClick={() => handleNavigate(flat._id)}>
                                    <img src={flat.mainImage} alt="" />
                                </button>
                            </div>
                            <div className="apartment-description">
                                <button onClick={() => handleNavigate(flat._id)}>
                                    {flat.name}
                                </button>
                                <p>
                                    {flat.subtitle}
                                </p>
                                <div className="navigation">
                                    {/* eslint no-underscore-dangle: 0 */}
                                    <button className="view-more" onClick={() => handleNavigate(flat._id)}>
                                        <img src={arrowRight} alt="" />
                                        <span>{t('common.viewMore')}</span>
                                    </button>
                                    <button className="map" onClick={() => window.open(flat.googleMap, '_blank')}>
                                        {t('navigation.map')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};
