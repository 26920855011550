import cn from 'classnames';
import { TLoaderColor, TLoaderPosition, TLoaderSize } from './Loader.typings';
import { loaderStyles } from './styles';
import './Loader.css';

type TLoaderProps = {
    size?: TLoaderSize
    color?: TLoaderColor
    position?: TLoaderPosition
    className?: string
};
const Loader = (props:TLoaderProps) => {
    const {
        color = 'primary',
        size = 'default',
        position = 'default',
        className,
    } = props;
    const loaderClassNames = cn(
        'Loader',
        loaderStyles.base,
        loaderStyles.size[size],
        loaderStyles.position[position],
        className,
    );
    const loaderItemClassNames = (idx: '1' | '2' | '3' | '4') => cn(
        `Loader-item Loader-item-${idx}`,
        loaderStyles.item.base,
        loaderStyles.item.idx[idx],
        loaderStyles.item.idx.color[color][idx],
        loaderStyles.item.size[size],
    );

    return (
        <span className={loaderClassNames}>
            <div className={loaderItemClassNames('1')} />
            <div className={loaderItemClassNames('2')} />
            <div className={loaderItemClassNames('3')} />
            <div className={loaderItemClassNames('4')} />
        </span>
    );
};

export default Loader;
