import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getAllFlatApiService } from './getAllFlats.service';
import { IGetAllFlatModelResponse } from './getAllFlats.model';
import { useAppDispatch } from '../../store/hooks';
import { changeFlats } from '../../store/slices/flats';

export const useGetAllFlats = () => {
    const dispatch = useAppDispatch();
    const { isFetching: isLoading, data } = useQuery<IGetAllFlatModelResponse>(
        ['get-all-flats'],
        () => getAllFlatApiService.getAllFlats(),
    );

    useEffect(() => {
        if (data !== undefined && !isLoading) {
            dispatch(changeFlats(data.flats));
        }
    }, [data, isLoading, dispatch]);

    return {
        allFlatsData: data,
        isLoadingAllFlats: isLoading,
    };
};
