import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './slices/auth';
import { landingInfoReducer } from './slices/landingInfo';
import { flatsSliceReducer } from './slices/flats';

const reducers = combineReducers({
    auth: authReducer,
    landingInfo: landingInfoReducer,
    flats: flatsSliceReducer,
});

export default reducers;
