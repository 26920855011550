import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AGBPdf from '../../assets/file/agb.PDF';

export const AGBPage = () => (
    <div className="agb-page">
        <iframe title="agb" src={AGBPdf} frameBorder="0" />
    </div>
);
