import axiosManagerUtil from '../axiosManager';
import { ISendCallBackResponse, TSendCallBackRequest } from './sendCallback.model';

const baseUrl = 'https://senatorflats-api-001301b73c6f.herokuapp.com/callbacks/add/callBacks';

export const sendCallbackInfoApiService = {
    sendCallbackInfo(data:TSendCallBackRequest) {
        return axiosManagerUtil.post$<ISendCallBackResponse>(
            `${baseUrl}`,
            data,
            { headers: { 'Content-Type': 'application/json' } },
        );
    },
};
