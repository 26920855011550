import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { IGetLandingInfoResponse } from './getLandingInfo.model';
import { getLandingInfoApiService } from './getLandingInfo.service';
import { useAppDispatch } from '../../store/hooks';
import { changeLandingInfo } from '../../store/slices/landingInfo';

export const useGetLandingInfo = () => {
    const dispatch = useAppDispatch();
    const { isFetching: isLoading, data } = useQuery<IGetLandingInfoResponse>(
        ['get-landing-info'],
        () => getLandingInfoApiService.getLandingInfo(),
    );

    useEffect(() => {
        if (data !== undefined && !isLoading) {
            dispatch(changeLandingInfo(data.landingInfo[0]));
        }
    }, [data, isLoading, dispatch]);
    return {
        landingInfoData: data,
        isLoadingLandingInfo: isLoading,
    };
};
