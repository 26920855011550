export const loaderStyles = {
    base: 'inline-block flex flex-wrap',
    size: {
        default: 'w-[4.25rem] h-[4.25rem]',
        small: 'w-[2.125rem] h-[2.125rem]',
    },
    position: {
        default: 'relative',
        center: 'absolute inset-1/2',
    },
    item: {
        base: 'inline-block absolute',
        idx: {
            color: {
                primary: {
                    1: 'bg-red',
                    2: 'bg-blue',
                    3: 'bg-yellow',
                    4: 'bg-green',
                },
                bright: {
                    1: 'bg-bright',
                    2: 'bg-bright',
                    3: 'bg-bright',
                    4: 'bg-bright',
                },
                typography: {
                    1: 'bg-typography',
                    2: 'bg-typography',
                    3: 'bg-typography',
                    4: 'bg-typography',
                },
            },
            1: 'top-0 left-0 animate-[animation-item1_3.2s_ease-out_infinite]',
            2: 'top-0 right-0 animate-[animation-item2_3.2s_ease-out_infinite]',
            3: 'bottom-0 right-0 animate-[animation-item3_3.2s_ease-out_infinite]',
            4: 'bottom-0 left-0 animate-[animation-item4_3.2s_ease-out_infinite]',
        },
        size: {
            default: 'w-8 h-8',
            small: 'w-4 h-4',
        },
    },
};
