import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { ILandingInfo } from '../../service/getLandingInfo/getLandingInfo.model';

interface ILandingState extends ILandingInfo {
    isLoading: boolean
}

const initialState: ILandingState = {
    isLoading: true,
    mainTitle: '',
    mainLogo: '',
    mainBanner: '',
    callBackTitle: '',
    feedBackTitle: '',
    facebookLink: '',
    phoneNumber: '',
    email: '',
    googleMapId: '',
};

export const landingInfoSlice = createSlice({
    name: 'landingInfo',
    initialState,
    reducers: {
        changeLandingInfo: (state, action: PayloadAction<ILandingInfo>) => {
            state.email = action.payload.email;
            state.mainTitle = action.payload.mainTitle;
            state.mainLogo = action.payload.mainLogo;
            state.mainBanner = action.payload.mainBanner;
            state.callBackTitle = action.payload.callBackTitle;
            state.feedBackTitle = action.payload.feedBackTitle;
            state.facebookLink = action.payload.facebookLink;
            state.phoneNumber = action.payload.phoneNumber;
            state.googleMapId = action.payload.googleMapId;
            state.isLoading = false;
        },
    },
});

export const {
    changeLandingInfo,
} = landingInfoSlice.actions;

export const selectLandingInfo = (state: RootState) => state[landingInfoSlice.name];

export const landingInfoReducer = landingInfoSlice.reducer;
