import React from 'react';
import './styles.scss';

const NotFoundPage = () => (
    <div className="not-found-page">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
    </div>
);

export default NotFoundPage;
