import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './modules/HomePage';
import ApartmentsPage from './modules/Apartments';
import MapPage from './modules/Map';
import ContactPage from './modules/Contact';
import AnreisePage from './modules/Anreise';
import DetailedApartmentPage from './modules/DetailedApartment';
import { AGBPage } from './modules/AGB/AGB';
import ImpressumPage from './modules/Impressum';
import Cookie from './components/Cookie/Cookie';
import { useGetLandingInfo } from './service/getLandingInfo/useGetLandingInfo';
import { useGetAllFlats } from './service/getAllFlats/useGetAllFlats';
import Preloader from './components/Preloader';
import { DistrictApartments } from './modules/Apartments/DistrictApartments';
import { useScrollToTop } from './hooks/useScrollTop';
import NotFoundPage from './components/NotFoundPage';

function App() {
    const { isLoadingLandingInfo } = useGetLandingInfo();
    const { isLoadingAllFlats } = useGetAllFlats();

    useScrollToTop();

    return (
        <Preloader isLoading={isLoadingLandingInfo || isLoadingAllFlats}>
            <div className="App">
                <Cookie />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/apartments" element={<ApartmentsPage />} />
                    <Route path="/map" element={<MapPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/anreise" element={<AnreisePage />} />
                    <Route path="/agb" element={<AGBPage />} />
                    <Route path="/impressum" element={<ImpressumPage />} />
                    <Route path="/detailed-apartment/:flatId/*" element={<DetailedApartmentPage />} />
                    <Route path="/apartments/:district" element={<DistrictApartments />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
        </Preloader>
    );
}

export default App;
