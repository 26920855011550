// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectLandingInfo } from '../../store/slices/landingInfo';
import facebook from '../../assets/svg/facebook-icon.svg';
import Preloader from '../Preloader';
import './styles.scss';

export const AnyReactComponent = ({ text }:any) => (
    <div style={{
        color: 'white',
        background: 'grey',
        padding: '15px 10px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        transform: 'translate(-50%, -50%)',
    }}
    >
        {text}
    </div>
);
export const Footer = () => {
    const { t } = useTranslation();
    const landingInfo = useSelector(selectLandingInfo);
    // const defaultProps = {
    //     center: {
    //         lat: 10.99835602,
    //         lng: 77.01502627,
    //     },
    //     zoom: 11,
    // };

    return (
        <Preloader isLoading={landingInfo.isLoading}>
            <footer id="footer">
                <div className="wrapper">
                    <div className="footer-main">
                        <div className="footer-contact">
                            <h4>
                                {t('navigation.contact')}
                            </h4>
                            <p>
                                {t('footer.phone:')}
                                <a href={`tel:${landingInfo.phoneNumber}`}>
                                    {landingInfo.phoneNumber}
                                </a>
                            </p>
                            <div className="social-links">
                                <a href={landingInfo.facebookLink}>
                                    <img src={facebook} alt="Facebook" />
                                </a>
                            </div>
                        </div>
                        <div className="footer-navigation">
                            <ul>
                                <li>
                                    <Link to="/">
                                        {t('navigation.home')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/apartments">
                                        {t('navigation.apartments')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/map">
                                        {t('navigation.map')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/agb">
                                        {t('navigation.agb')}
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="/impressum">
                                        {t('navigation.impressum')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        {t('navigation.contact')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/anreise">
                                        {t('navigation.anreise')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-map">
                        {/* <GoogleMapReact */}
                        {/*    defaultCenter={defaultProps.center} */}
                        {/*    defaultZoom={defaultProps.zoom} */}
                        {/* > */}
                        {/*    <AnyReactComponent */}
                        {/*        lat={59.955413} */}
                        {/*        lng={30.337844} */}
                        {/*        text="Kreyser Avrora" */}
                        {/*    /> */}
                        {/* </GoogleMapReact> */}
                    </div>
                </div>
            </footer>
        </Preloader>
    );
};
