import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectAllFlats, selectAllFlatsLoading } from '../../store/slices/flats';
import arrowRight from '../../assets/svg/arrow-right-black.svg';

import Preloader from '../../components/Preloader';
import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';

export const ApartmentsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const allFlats = useSelector(selectAllFlats);
    const allFlatsLoading = useSelector(selectAllFlatsLoading);
    const tempFilteredFlats = allFlats.filter((flat) => flat._id !== '65821c917c4b0cedfdfff184');

    const handleNavigate = (id:string) => {
        navigate(`/detailed-apartment/${id}`);
    };

    return (
        <Preloader isLoading={allFlatsLoading}>
            <div className="apartments-page">
                <div className="apartments-page">
                    <div className="apartments-list">
                        {
                            tempFilteredFlats.map((flat) => (
                                /*  eslint-disable no-underscore-dangle  */
                                <div className="apartment" key={flat._id}>
                                    <div className="apartment-photo">
                                        <button onClick={() => handleNavigate(flat._id)}>
                                            <img src={flat.mainImage} alt="" />
                                        </button>
                                    </div>
                                    <div className="apartment-description">
                                        <button onClick={() => handleNavigate(flat._id)}>
                                            {flat.name}
                                        </button>
                                        <p>
                                            {flat.subtitle}
                                        </p>
                                        <div className="navigation">
                                            {/* eslint no-underscore-dangle: 0 */}
                                            <button className="view-more" onClick={() => handleNavigate(flat._id)}>
                                                <img src={arrowRight} alt="" />
                                                <span>{t('common.viewMore')}</span>
                                            </button>
                                            <button className="map" onClick={() => window.open(flat.googleMap, '_blank')}>
                                                {t('navigation.map')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Preloader>
    );
};
