import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import Divider from '../../components/Divider';

export const ImpressumPage = () => (
    <div className="contact-page">
        <div className="contact-title">
            <Divider>
                Impressum
            </Divider>
        </div>
        <div className="contact-block">
            <div className="left-side">
                <span className="bold">
                    Für den Inhalt der Website verantwortlicher Herausgeber:
                </span>
                <span>
                    Günther GmbH
                    <br />
                    Paulusgasse 3/12, A-1030 Wien
                    <br />
                    Tel.: +436644060777
                    <br />
                    Email: office@senator-flats.com
                    <br />
                    Web: www.senator-flats.com
                    <br />
                    Geschäftsführer: Gerhard Günther
                </span>
            </div>
            <div className="right-side">
                <span>
                    Firmenbuchnummer: FN236291m
                    <br />
                    Firmenbuchgericht: Gerichtsstand Wien
                    <br />
                    Aufsichtsbehörde: Magistrat der Stadt Wien
                    <br />
                    Mitgliedschaft: WKÖ
                    <br />
                    UID Nummer: ATU66069808
                    <br />
                    Website Betreuung: Vitaliy Romaniv
                </span>
            </div>
        </div>
    </div>
);
