import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { IFlatDescription } from '../../service/getAllFlats/getAllFlats.model';

interface IAllFlats {
    allFlats: IFlatDescription[];
    isLoading: boolean
}
const initialState: IAllFlats = {
    allFlats: [
        {
            price: 0,
            peopleCount: 0,
            mainImage: '',
            images: [],
            name: '',
            subtitle: '',
            googleMap: '',
            transfer: '',
            wifi: '',
            district: '',
            address: {
                country: '',
                city: '',
                addressLine: '',
            },
            _id: '',
        },
    ],
    isLoading: true,
};

export const flatsSlice = createSlice({
    name: 'flats',
    initialState,
    reducers: {
        changeFlats: (state, action: PayloadAction<IFlatDescription[]>) => {
            state.allFlats = [...action.payload];
            state.isLoading = false;
        },
    },
});

export const {
    changeFlats,
} = flatsSlice.actions;

export const selectAllFlats = (state: RootState) => state[flatsSlice.name].allFlats;
export const selectAllFlatsLoading = (state: RootState) => state[flatsSlice.name].isLoading;

export const flatsSliceReducer = flatsSlice.reducer;
