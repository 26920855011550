import './styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLandingInfo } from '../../store/slices/landingInfo';

export const AnreisePage = () => {
    const { t } = useTranslation();
    const landingInfo = useSelector(selectLandingInfo);

    return (
        <div className="anreise-page">
            <div className="anreise-title">
                {t('anreise.yourArrival')}
            </div>
            <div className="anreise-block">
                <span className="title">
                    {`🇬🇧 ${t('anreise.airportService')}`}
                </span>
                <p>
                    {t('anreise.airportServiceDesc1')}
                    {t('anreise.airportServiceDesc2')}
                </p>

                <span className="title">
                    {`📱 ${t('anreise.reachOurDriver')}`}
                </span>
                <div className="price">
                    <h4>
                        {t('anreise.pricesPerWay')}
                    </h4>
                    <p>
                        {t('anreise.upTo3People')}
                    </p>
                    <p>
                        {t('anreise.upTo5People')}
                    </p>
                    <p>
                        {t('anreise.upTo8People')}
                    </p>
                </div>
                <span>
                    {t('anreise.sendUsYourFlightDetails')}
                </span>

                <div className="contact-button">
                    <a href={`mailto:${landingInfo.email}`}>
                        {landingInfo.email}
                    </a>
                    <a href={`tel:${landingInfo.phoneNumber}`}>
                        {landingInfo.phoneNumber}
                    </a>
                </div>
            </div>
            <div className="banner-block">
                <span>
                    {t('anreise.lookForward')}
                </span>
            </div>
        </div>
    );
};
