import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import Cookies from 'js-cookie';
import { changeCookie, selectCookieAcceptance } from '../../store/slices/auth';
import { useAppDispatch } from '../../store/hooks';
import './styles.scss';

const Cookie = () => {
    const dispatch = useAppDispatch();
    const isCookieAccepted = useSelector(selectCookieAcceptance);
    const savedCookie = Cookies.get('userCookieAcceptance');

    useEffect(() => {
        if (savedCookie !== undefined) {
            dispatch(changeCookie(true));
        }
    }, [savedCookie, dispatch]);

    const handleAccept = () => {
        dispatch(changeCookie(true));
        Cookies.set('userCookieAcceptance', 'Cookie accepted from the user', { expires: 7 });
    };

    if (isCookieAccepted) return null;

    return (
        <div id="cookiemodal" className="cookie-modal">
            <div className="cookie-modal-container">
                <div className="cookie-modal-container-content">
                    <div className="cookie-modal-container-content-title">Cookie control</div>
                    <div className="cookie-modal-container-content-text">
                        <p>
                            We use cookies to personalize site content,
                            social media features and to analyze our traffic.
                            We also share information about your use of this site with
                            our advertising and social media partners.
                        </p>

                        <p>
                            By closing this banner, clicking a link or continuing
                            to browse otherwise, you agree to the use of cookies.
                        </p>
                    </div>
                    <div className="cookie-modal-container-content-wrapper">
                        <Button
                            id="cookieagree"
                            role="button"
                            className="cookie-modal-container-content-button"
                            onClick={handleAccept}
                        >
                            Accept
                        </Button>
                        <a href="/agb" className="cookie-modal-container-content-link" target="_blank">
                            <span>Privacy policy</span>
                        </a>
                    </div>
                </div>
                <Button
                    id="cookieclose"
                    className="cookie-modal-container-close"
                    onClick={handleAccept}
                />
            </div>
        </div>
    );
};

export default Cookie;
